.titleContainer {
	flex: 0 0 auto;
	padding: 15px 6px;
	margin-bottom: 3px;
	color: #656565;
}

.mainMenuContainer {
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	border-bottom: 1px solid #e7e8ec;
	border-width: thin;
	padding: 0 10px 0 10px;
	
	-webkit-overflow-scrolling: touch !important;
	white-space: nowrap;
	overflow-x: scroll;
	overflow-y: hidden;
}

.mainMenuContainer .active {
	color: var(--header_text_alternate);
	border-bottom: 3px solid var(--attach_picker_tab_active_text);
	margin-bottom: 0px;
}

.mainMenuContainer::-webkit-scrollbar {
	display: none;
}

.mainMenuContainer::-webkit-scrollbar-button {
	display: none;
}